export const i18n_fr = {
  "Chargement...": "Chargement...",
  "Votre session semble avoir expirée, merci de vous reconnecter, svp": "Votre session semble avoir expirée, merci de vous reconnecter, svp",
  "Retourner sur la page de connexion": "Retourner sur la page de connexion",
  "2023 | Propriété exclusive de": "2023 | Propriété exclusive de",
  "A propos": "A propos",
  "Notre philosophie": "Notre philosophie",
  "Contactez-nous !": "Contactez-nous !",
  "Vue rapide": "Vue rapide",
  "Prochaines échéances": "Prochaines échéances",
  "Mises à jours": "Mises à jours",
  "Paramètres du compte": "Paramètres du compte",
  "Déconnexion": "Déconnexion",
  "Retour": "Retour",
  "RECHERCHE DE DISPONIBILITéS": "RECHERCHE DE DISPONIBILITéS",
  "ADMINISTRATION DU SITE": "ADMINISTRATION DU SITE",
  "Mot de passe oublié ?": "Mot de passe oublié ?",
  "Veuillez nous contacter au": "Veuillez nous contacter au",
  "afin de procéder à la vérification de votre identité": "afin de procéder à la vérification de votre identité",
  "et la réinitilisation de votre mot de passe.": "et la réinitilisation de votre mot de passe.",
  "Retour à la page de connexion": "Retour à la page de connexion",
  "Connexion à still'Quiz": "Connexion à still'Quiz",
  "Vous avez déjà un compte ?": "Vous avez déjà un compte ?",
  "Se connecter via Linkedin": "Se connecter via Linkedin",
  "OU": "OU",
  "Identifiant": "Identifiant",
  "Mot de passe": "Mot de passe",
  "Se connecter": "Se connecter",
  "Vous n'avez pas encore de compte ?": "Vous n'avez pas encore de compte ?",
  "Créer un compte utilisateur": "Créer un compte utilisateur",
  "Connexion établie !": "Connexion établie !",
  "Bienvenue": "Bienvenue",
  "sur votre espace still'Quiz !": "sur votre espace still'Quiz !",
  "Accèder à still'Quiz": "Accèder à still'Quiz",
  "Votre compte est désactivé, veuillez contacter Stillnetwork afin de pouvoir vous reconnecter.": "Votre compte est désactivé, veuillez contacter Stillnetwork afin de pouvoir vous reconnecter.",
  "L'identifiant doit être un email valide": "L'identifiant doit être un email valide",
  "L'identifiant est obligatoire": "L'identifiant est obligatoire",
  "Le mot de passe est obligatoire": "Le mot de passe est obligatoire",
  "Il semble y avoir une erreur, veuillez vérifier vous identifiants de connexion.": "Il semble y avoir une erreur, veuillez vérifier vous identifiants de connexion.",
  "Création de compte utilisateur": "Création de compte utilisateur",
  "Prénom": "Prénom",
  "Nom": "Nom",
  "Société": "Société",
  "Téléphone": "Téléphone",
  "Email": "Email",
  "Confirmer le mot de passe": "Confirmer le mot de passe",
  "Vous acceptez les": "Vous acceptez les",
  "Termes et conditions": "Termes et conditions",
  "Créer votre compte still'Quiz": "Créer votre compte still'Quiz",
  "Veuillez patienter...": "Veuillez patienter...",
  "Le prénom est requis": "Le prénom est requis",
  "Le nom est requis": "Le nom est requis",
  "Numéro valide": "Numéro valide",
  "Numéro non valide": "Numéro non valide",
  "L'email doit contenir au moins 4 caractères": "L'email doit contenir au moins 4 caractères",
  "Email non valide": "Email non valide",
  "L'email est requis": "L'email est requis",
  "Email valide": "Email valide",
  "Veuillez utiliser une adresse professionnelle": "Veuillez utiliser une adresse professionnelle",
  "Le mot de passe doit contenir au moins 4 caractères": "Le mot de passe doit contenir au moins 4 caractères",
  "Le mot de passe est requis": "Le mot de passe est requis",
  "Erreur lors de la confirmation du mot de passe": "Erreur lors de la confirmation du mot de passe",
  "Le mot de passe doit correspondre": "Le mot de passe doit correspondre",
  "Veuillez accepter nos conditions": "Veuillez accepter nos conditions",
  "Votre compte a bien été crée !": "Votre compte a bien été crée !",
  "Oups...": "Oups...",
  "Retourner à l'accueil": "Retourner à l'accueil",
  "Liste des Producteurs et des Fournisseurs": "Liste des Producteurs et des Fournisseurs",
  "Aucune ligne à afficher": "Aucune ligne à afficher",
  "Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.": "Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.",
  "Modifier": "Modifier",
  "Aucune ligne à afficher.": "Aucune ligne à afficher.",
  "Collectivité": "Collectivité",
  "Personne": "Personne",
  "Famille": "Famille",
  "Type inconnu": "Type inconnu",
  "Gestion des utilisateurs": "Gestion des utilisateurs",
  "Historique des recherches": "Historique des recherches",
  "Historique des reservations": "Historique des reservations",
  "Créer un utilisateur": "Créer un utilisateur",
  "Identité": "Identité",
  "Coordonnées": "Coordonnées",
  "Paramètrage": "Paramètrage",
  "Lien vers un AM Stillnetwork": "Lien vers un AM Stillnetwork",
  "Tant qu'aucun lien vers un utilisateur still n'est sélectionnée, chaque action de cet utilisateur sera envoyé sur le mail générique.": "Tant qu'aucun lien vers un utilisateur still n'est sélectionnée, chaque action de cet utilisateur sera envoyé sur le mail générique.",
  "Annuler": "Annuler",
  "l'utilisateur": "l'utilisateur",
  "Il n'existe aucun historique de recherche sur cet utilisateur.": "Il n'existe aucun historique de recherche sur cet utilisateur.",
  "Fermer": "Fermer",
  "Spécifier le Prénom": "Spécifier le Prénom",
  "Spécifier le nom": "Spécifier le nom",
  "Spécifier la société": "Spécifier la société",
  "Spécifier le Téléphone": "Spécifier le Téléphone",
  "Spécifier le Mail *": "Spécifier le Mail *",
  "Utilisateur adminstrateur": "Utilisateur adminstrateur",
  "Utilisateur Stillnetwork": "Utilisateur Stillnetwork",
  "Compte désactivé": "Compte désactivé",
  "Selection AM Stillnetwork": "Selection AM Stillnetwork",
  "Historique de recherche de l'utilisateur": "Historique de recherche de l'utilisateur",
  "Aucun historique à afficher.": "Aucun historique à afficher.",
  "Créer": "Créer",
  "d'un utilisateur": "d'un utilisateur",
  "Création": "Création",
  "Modification": "Modification",
  "Le champ Prénom est obligatoire": "Le champ Prénom est obligatoire",
  "Le champ Nom est obligatoire": "Le champ Nom est obligatoire",
  "Le champ Email est obligatoire": "Le champ Email est obligatoire",
  "Utilisateur modifié avec succès !": "Utilisateur modifié avec succès !",
  "Utilisateur créé avec succès !": "Utilisateur créé avec succès !",
  "Erreur": "Erreur",
  "Succès": "Succès",
  "Recherches effectuées par les utilisateurs": "Recherches effectuées par les utilisateurs",
  "AM Still':": "AM Still':",
  "Aucune recherche effectuée": "Aucune recherche effectuée",
  "Reservations effectuées par les utilisateurs": "Reservations effectuées par les utilisateurs",
  "Afficher la réservation": "Afficher la réservation",
  "Commentaire :": "Commentaire :",
  "Liste des utilisateurs": "Liste des utilisateurs",
  "Adminstrateur": "Adminstrateur",
  "Historique": "Historique",
  "Oui, réinitialiser le mot de passe": "Oui, réinitialiser le mot de passe",
  "Non": "Non",
  "Réinitialiser le mot de passe et renvoyer un mail à l'utilisateur ?": "Réinitialiser le mot de passe et renvoyer un mail à l'utilisateur ?",
  "Mot de passe réinitialisé avec succès !": "Mot de passe réinitialisé avec succès !",
  "LECTURE SEULE": "LECTURE SEULE",
  "Export Arkheïa": "Export Arkheïa",
  "Export XML": "Export XML",
  "Export PDF": "Export PDF",
  "Export JSON": "Export JSON",
  "Liste des articles": "Liste des articles",
  "Dates :": "Dates :",
  "/ DUA :": "/ DUA :",
  "Observation": "Observation",
  "Oui": "Oui",
  "Numéro d'ordre": "Numéro d'ordre",
  "Libellé": "Libellé",
  "Dates": "Dates",
  "N° d'ordre": "N° d'ordre",
  "Titre": "Titre",
  "Dates extrêmes": "Dates extrêmes",
  "Métrage Linéaire": "Métrage Linéaire",
  "ml": "ml",
  "DUA spécifique": "DUA spécifique",
  "Années": "Années",
  "A spécifier si différent du bordereau": "A spécifier si différent du bordereau",
  "Observations": "Observations",
  "Référence": "Référence",
  "réglementaire": "réglementaire",
  "Créer un nouvel article": "Créer un nouvel article",
  "Exporter le détail du bordereau": "Exporter le détail du bordereau",
  "Oui, Supprimer": "Oui, Supprimer",
  "Confirmez-vous la suppression de la ligne ?": "Confirmez-vous la suppression de la ligne ?",
  "Sans date": "Sans date",
  "Année de début": "Année de début",
  "Année de fin": "Année de fin",
  "Saisir": "Saisir",
  "DUA": "DUA",
  "Facultatif...": "Facultatif...",
  "Modifier l'article": "Modifier l'article",
  "Créér l'article": "Créér l'article",
  "d'un article": "d'un article",
  "de versement": "de versement",
  "d'élimination": "d'élimination",
  "Article supprimé de la liste.": "Article supprimé de la liste.",
  "L'année début semble être incorrecte. Veuillez vérifier votre saisie, svp": "L'année début semble être incorrecte. Veuillez vérifier votre saisie, svp",
  "L'année de fin semble être incorrecte. Veuillez vérifier votre saisie, svp": "L'année de fin semble être incorrecte. Veuillez vérifier votre saisie, svp",
  "L'année de fin ne peut être inférieur à l'année de début. Veuillez vérifier votre saisie, svp": "L'année de fin ne peut être inférieur à l'année de début. Veuillez vérifier votre saisie, svp",
  "Le métrage linéaire doit être un nombre ou vide. Veuillez vérifier votre saisie, svp": "Le métrage linéaire doit être un nombre ou vide. Veuillez vérifier votre saisie, svp",
  "Article modifié avec succès !": "Article modifié avec succès !",
  "Article créé avec succès !": "Article créé avec succès !",
  "Description du bordereau": "Description du bordereau",
  "Veuillez sélectionner un type de versement ci-dessous.": "Veuillez sélectionner un type de versement ci-dessous.",
  "PAPIER": "PAPIER",
  "DOSSIERS PHYSIQUES": "DOSSIERS PHYSIQUES",
  "NUMERIQUES": "NUMERIQUES",
  "FICHIER NUMÉRIQUES": "FICHIER NUMÉRIQUES",
  "MIXTE": "MIXTE",
  "PAPIER ET NUMÉRIQUES": "PAPIER ET NUMÉRIQUES",
  "Versement de type : Papier": "Versement de type : Papier",
  "Nombre total d'articles": "Nombre total d'articles",
  "Nombre de boîtes": "Nombre de boîtes",
  "boites": "boites",
  "Métrage": "Métrage",
  "Type de support": "Type de support",
  "Format": "Format",
  "Versement de type : Fichier numériques": "Versement de type : Fichier numériques",
  "Nombre de fichier": "Nombre de fichier",
  "Volumétrie": "Volumétrie",
  "Support de stockage": "Support de stockage",
  "Description sommaire": "Description sommaire",
  "Articles extrêmes": "Articles extrêmes",
  "Années extrêmes": "Années extrêmes",
  "Description": "Description",
  "Calculer le nombre total d'article du bordereau": "Calculer le nombre total d'article du bordereau",
  "Calculer le métrage linaire des articles du bordereau": "Calculer le métrage linaire des articles du bordereau",
  "Papier": "Papier",
  "Photo": "Photo",
  "Plan": "Plan",
  "Autre": "Autre",
  "A4": "A4",
  "A3": "A3",
  "CD / DVD": "CD / DVD",
  "Clef USB": "Clef USB",
  "Disque dur externe": "Disque dur externe",
  "Bureautique": "Bureautique",
  "PDF": "PDF",
  "Image": "Image",
  "Vidéo": "Vidéo",
  "Son": "Son",
  "Calculer les années extrêmes": "Calculer les années extrêmes",
  "Ko": "Ko",
  "Mo": "Mo",
  "Go": "Go",
  "To": "To",
  "Po": "Po",
  "Traitement du bordereau": "Traitement du bordereau",
  "Type de bordereau": "Type de bordereau",
  "Basculer sur un bordereau": "Basculer sur un bordereau",
  "Nombre d'articles à éliminer": "Nombre d'articles à éliminer",
  "Années / Dates extrêmes": "Années / Dates extrêmes",
  "Mot-clés": "Mot-clés",
  "Nouveau Mot-clé": "Nouveau Mot-clé",
  "Référence réglementaire": "Référence réglementaire",
  "Délai et durée": "Délai et durée",
  "Délai de communicabilité maximum": "Délai de communicabilité maximum",
  "an(s)": "an(s)",
  "Communicabilité totalité du versement": "Communicabilité totalité du versement",
  "Identification du service": "Identification du service",
  "Rédacteur": "Rédacteur",
  "Archiviste": "Archiviste",
  "Chef de service": "Chef de service",
  "Aucun Chef de service": "Aucun Chef de service",
  "Direction des archives": "Direction des archives",
  "Aucune direction des archives": "Aucune direction des archives",
  "Conservation intégrale": "Conservation intégrale",
  "Conservation partielle": "Conservation partielle",
  "Elimination": "Elimination",
  "Spécifier ici les références réglementaires, si existante...": "Spécifier ici les références réglementaires, si existante...",
  "Choix de la date": "Choix de la date",
  "Selection": "Selection",
  "PDF du bordereau": "PDF du bordereau",
  "BORDEREAU": "BORDEREAU",
  "Souhaitez-vous réellement": "Souhaitez-vous réellement",
  "Confirmation": "Confirmation",
  "Oui,": "Oui,",
  "Une notification sera envoyée à": "Une notification sera envoyée à",
  "pour traitement du bordereau.": "pour traitement du bordereau.",
  "En cours": "En cours",
  "supprimer ce bordereau": "supprimer ce bordereau",
  "demander la validation auprès de l'archiviste": "demander la validation auprès de l'archiviste",
  "En attente validation archiviste": "En attente validation archiviste",
  "rejeter ce bordereau": "rejeter ce bordereau",
  "valider ce bordereau": "valider ce bordereau",
  "En attente visa chef de service": "En attente visa chef de service",
  "refuser ce bordereau": "refuser ce bordereau",
  "apposer un VISA de validation sur ce bordereau": "apposer un VISA de validation sur ce bordereau",
  "En attente visa direction archive": "En attente visa direction archive",
  "Terminé": "Terminé",
  "Supprimé": "Supprimé",
  "Le champ Fournisseur/ Producteur est obligatoire": "Le champ Fournisseur/ Producteur est obligatoire",
  "Le champ Archiviste  est obligatoire": "Le champ Archiviste  est obligatoire",
  "Bordereau créé avec succès !": "Bordereau créé avec succès !",
  "Détail du produit": "Détail du produit",
  "Constructeur": "Constructeur",
  "Catégorie": "Catégorie",
  "Délai usine": "Délai usine",
  "Sous": "Sous",
  "Soit à partir du": "Soit à partir du",
  "Stock neuf disponible": "Stock neuf disponible",
  "Livrable sous 48 heures": "Livrable sous 48 heures",
  "Aucun stock neuf": "Aucun stock neuf",
  "Stock reconditionné": "Stock reconditionné",
  "Livrable sous 5 jours": "Livrable sous 5 jours",
  "Livrable sous 9 jours": "Livrable sous 9 jours",
  "Livrable sous 1 mois": "Livrable sous 1 mois",
  "Distributeurs": "Distributeurs",
  "Aucun stock disponible chez les distributeurs.": "Aucun stock disponible chez les distributeurs.",
  "Select": "Select",
  "Visuel du produit": "Visuel du produit",
  "Spécificités CISCO": "Spécificités CISCO",
  "Sous Groupe CISCO": "Sous Groupe CISCO",
  "Nom de service": "Nom de service",
  "Indicateur de configuration": "Indicateur de configuration",
  "Entité interne": "Entité interne",
  "Type": "Type",
  "inconnu": "<i>inconnu</i>",
  "jours": "jours",
  "Recherche en cours...": "Recherche en cours...",
  "Lancer la recherche": "Lancer la recherche",
  "Pour rechercher plusieurs références spécifiques, veuillez les séparer par un": "Pour rechercher plusieurs références spécifiques, veuillez les séparer par un",
  "point virgule": "point virgule",
  "par exemple :": "par exemple :",
  "Résultat de votre recherche": "Résultat de votre recherche",
  "résultat": "résultat",
  "Quantité disponible :": "Quantité disponible :",
  "Livrable sous 48h": "Livrable sous 48h",
  "Aucun stock": "Aucun stock",
  "Délai usine de": "Délai usine de",
  "Voir le détail du produit": "Voir le détail du produit",
  "Neuf - Livrable sous 48h": "Neuf - Livrable sous 48h",
  "Reconditionnés - Voir détails": "Reconditionnés - Voir détails",
  "Réserver": "Réserver",
  "Détails": "Détails",
  "Pour continuer, vous devez vous créer un compte.": "Pour continuer, vous devez vous créer un compte.",
  "C'est simple, rapide, gratuit et sans engagement !": "C'est simple, rapide, gratuit et sans engagement !",
  "Accèder à tout notre stock produits,": "Accèder à tout notre stock produits,",
  "Veillez à avoir une vue claire sur chaque disponibilité": "Veillez à avoir une vue claire sur chaque disponibilité",
  "Vérifier les dates de réapprovisionnement,": "Vérifier les dates de réapprovisionnement,",
  "Filtrer la recherche": "Filtrer la recherche",
  "Rechercher un ou des produits en saisissant le SKU": "Rechercher un ou des produits en saisissant le SKU",
  "Votre demande de réservation": "Votre demande de réservation",
  "Transmettre votre demande": "Transmettre votre demande",
  "Oui, retirer cette référence": "Oui, retirer cette référence",
  "Commentaires sur votre demande": "Commentaires sur votre demande",
  "Discussion autour du bordereau": "Discussion autour du bordereau",
  "Fichier :": "Fichier :",
  "Envoyer": "Envoyer",
  "Ecrire un nouveau message...": "Ecrire un nouveau message...",
  "Exporter": "Exporter",
  "Détails de votre profil utilisateur": "Détails de votre profil utilisateur",
  "Prénom Nom": "Prénom Nom",
  "Téléphone Fixe": "Téléphone Fixe",
  "Votre Service": "Votre Service",
  "Profil de connexion": "Profil de connexion",
  "Votre adresse Email": "Votre adresse Email",
  "Veuillez saisir votre adresse Email": "Veuillez saisir votre adresse Email",
  "Confirmer votre mot de passe": "Confirmer votre mot de passe",
  "Modifier votre Email": "Modifier votre Email",
  "Le mot de passe doit faire minimum 8 caractères.": "Le mot de passe doit faire minimum 8 caractères.",
  "Mot de passe actuel": "Mot de passe actuel",
  "Nouveau mot de passe": "Nouveau mot de passe",
  "Confirmation de votre nouveau mot de passe": "Confirmation de votre nouveau mot de passe",
  "Modifier votre mot de passe": "Modifier votre mot de passe",
  "Changer votre mot de passe": "Changer votre mot de passe",
  "Nom de famille": "Nom de famille",
  "Service": "Service",
  "Email Address": "Email Address",
  "Le prénom est obligatoire": "Le prénom est obligatoire",
  "Le nom est obligatoire": "Le nom est obligatoire",
  "Mot de passe actuel obligatoire": "Mot de passe actuel obligatoire",
  "Confirmation du nouveau Mot de passe obligatoire": "Confirmation du nouveau Mot de passe obligatoire",
  "Les nouveaux mots des passes doivent être identiques": "Les nouveaux mots des passes doivent être identiques",
  "Changement de vos informations personnelles correctement effectué !": "Changement de vos informations personnelles correctement effectué !",
  "Ok": "Ok",
  "Changement de votre Email correctement effectué !": "Changement de votre Email correctement effectué !",
  "Erreur lors du chagement de mail. Veuillez vérifier votre adresse Email et votre mot de passe, svp.": "Erreur lors du chagement de mail. Veuillez vérifier votre adresse Email et votre mot de passe, svp.",
  "Changement de votre mot de passe correctement effectué !": "Changement de votre mot de passe correctement effectué !",
  "Erreur lors du chagement de mot de passe. Veuillez vérifier vos informations et réessayer, svp.": "Erreur lors du chagement de mot de passe. Veuillez vérifier vos informations et réessayer, svp.",
  "Filtrer par": "Filtrer par",
  "Filtrer la liste": "Filtrer la liste",
  "Aucun filtre...": "Aucun filtre...",
  "Exporter et télécharger la liste au format Excel ?": "Exporter et télécharger la liste au format Excel ?",
  "Indisponible": "Indisponible",
  "Ref. Arkheia": "Ref. Arkheia",
  "Raison sociale": "Raison sociale",
  "Adresse": "Adresse",
  "Date": "Date",
  "Recherche": "Recherche",
  "Références": "Références",
  "Recherchées le": "Recherchées le",
  "Date de réservation": "Date de réservation",
  "Réservation": "Réservation",
  "Créé le": "Créé le",
  "Titre / Libellé": "Titre / Libellé",
  "Distributeur": "Distributeur",
  "Quantité": "Quantité",
  "Référence produit": "Référence produit",
  "Stock Stillnetwork": "Stock Stillnetwork",
  "utilisateur": "utilisateur",
  "AM": "AM",
  "titre": "titre",
  "Famille de produit": "Famille de produit",
  "Classe de produit": "Classe de produit",
  "Sous-classe de produit": "Sous-classe de produit",
  "Rechercher dans la liste": "Rechercher dans la liste",
  "Retirer de la liste ?": "Retirer de la liste ?",
  "Il n'y a aucune ligne dans votre demande de réservation": "Il n'y a aucune ligne dans votre demande de réservation"
}